// eslint-disable-next-line import/prefer-default-export
export function fat2Flat(obj, prefix = '') {
  let result = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of Object.entries(obj)) {
    if (typeof v === 'object' && v !== null) {
      if (!Array.isArray(v)) {
        result = Object.assign(result, fat2Flat(v, `${prefix}${k}.`))
      }
    } else {
      result[`${prefix}${k}`] = v
    }
  }
  return result
}

export function fillObject(obj = {}, data = {}) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of Object.entries(data)) {
    if (Object.hasOwn(obj, k)) {
      // eslint-disable-next-line no-param-reassign
      obj[k] = v
    }
  }
  return obj
}

export function filterRequestParams(obj = {}) {
  const result = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of Object.entries(obj)) {
    result[`filter[${k}]`] = v
  }
  return result
}
