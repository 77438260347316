import { fillObject } from '@/views/utils'
import { dateFormat } from '@/libs/filters'
import moment from 'moment'

export const TRANSMISSION_LABELS = {
  MT: 'Механика',
  AT: 'Автомат',
  MTA: 'Робот',
  CVT: 'Вариатор',
  EL: 'Элктро',
}

export const GAS_LABELS = {
  METHANE: 'Метан',
  PROPANE: 'Пропан',
}

export const INSURANCE_LABELS = {
  NONE: 'Нет',
  OPENED: 'Открыт',
  CLOSED: 'Закрыт',
  INSCRIBED: 'Вписан',
}

export const carsTableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'group',
    label: 'Группа',
    formatter(item) {
      return item ? item.title : '-'
    },
  },
  {
    key: 'mark',
    label: 'Марка',
  },
  {
    key: 'model',
    label: 'Модель',
  },
  {
    key: 'number',
    label: 'Гос.номер',
  },
  {
    key: 'vin',
  },
  {
    key: 'sts_number',
    label: 'Стс',
  },
  {
    key: 'mileage',
    label: 'Пробег',
  },
  {
    key: 'actions',
    label: '',
  },
]

export const carsNewTableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'number',
    label: 'Гос.номер',
    sortable: true,
  },
  {
    key: 'mark',
    label: 'Марка Модель',
    formatter() {
      // eslint-disable-next-line prefer-rest-params
      const item = arguments[2]
      return `${item.mark} ${item.model}`
    },
    sortable: true,
  },
  {
    key: 'sheet_driver',
    label: 'Водитель',
    formatter(driver) {
      return driver ? `${driver.last_name} ${driver.first_name} ${driver.middle_name}` : null
    },
    sortable: true,
  },
  {
    key: 'sheet',
    label: 'Дата',
    formatter(rent) {
      return rent ? dateFormat(rent.date_start, true, false) : null
    },
    sortable: true,
  },
  {
    key: 'plan_date',
    label: 'Планы',
    sortable: true,
  },
  {
    key: 'repair_date',
    label: 'Ремонт',
    sortable: true,
  },
  {
    key: 'transmission',
    label: 'КПП',
    formatter(trs) {
      return trs ? TRANSMISSION_LABELS[trs].slice(0, 5) : null
    },
    sortable: true,
  },
  {
    key: 'gas_equipment',
    label: 'ГБО',
    formatter(gas) {
      return gas ? GAS_LABELS[gas] : 'Нет'
    },
    sortable: true,
  },
  {
    key: 'brand',
    label: 'Бренд',
    formatter(brand) {
      return brand ? 'Да' : 'Нет'
    },
    sortable: true,
  },
  {
    key: 'resolution',
    label: 'Лицензия',
    sortable: true,
  },
  {
    key: 'tech_inspect',
    label: 'Техосмотр',
    formatter(tech) {
      return tech ? dateFormat(tech.date_end, true, false) : null
    },
    sortable: true,
  },
  {
    key: 'insurance',
    label: 'ОСАГО',
    formatter(insurance) {
      return insurance ? dateFormat(insurance.date_end, true, false) : null
    },
    sortable: true,
  },
  {
    key: 'insurance_status',
    label: 'Статус',
    formatter() {
      // eslint-disable-next-line prefer-rest-params
      const { insurance } = arguments[2]
      return insurance ? INSURANCE_LABELS[insurance.status] : null
    },
    sortable: true,
  },
  {
    key: 'actions',
    label: '',
  },
]

export const carActTable = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'id',
    label: 'Номер',
  },
  {
    key: 'driver',
    label: 'Водитель',
    formatter(driver) {
      return `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
    },
  },
  {
    key: 'car',
    label: 'Гос.Номер',
    formatter(car) {
      return car.number
    },
  },
  {
    key: 'mileage_start',
    label: 'Пробег',
    formatter(mileageStart, name, act) {
      return `${mileageStart} / ${act.mileage_end ?? '-'}`
    },
  },
  {
    key: 'date_start',
    label: 'Выезд',
    formatter(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '-'
    },
  },
  {
    key: 'date_end',
    label: 'Заезд',
    formatter(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '-'
    },
  },
  {
    key: 'comment',
    label: 'Примечание',
  },
  {
    key: 'actions',
    label: '',
  },
]

export class Car {
  id = null

  group_id = null

  mark = null

  model = null

  number = null

  vin = null

  sts_number = null

  resolution = null

  owner = null

  owner_date = null

  registration_form = null

  registration_number = null

  equipped = null

  hide_crm = 0

  hide_driver = 0

  created_at = null

  created_by = null

  updated_at = null

  updated_by = null

  brand = false

  gas_equipment = null

  transmission = 'MT'

  insurance = null

  rent_driver = null

  tech_inspect = null

  plan_date = null

  repair_date = null

  constructor(obj = {}) {
    fillObject(this, obj)
  }

  title = () => `${this.mark} ${this.model} ${this.number}`
}

export const carViewLabels = {
  owner: 'Собственник',
  mark: 'Марка',
  model: 'Модель',
  number: 'Гос номер',
  vin: 'VIN',
  sts_number: 'Стс',
  registration_form: 'Форма регистрации',
  registration_number: 'Номер регистрации',
  resolution: 'Резрешение',
  mileage: 'Пробег',
  created_at: 'Добавлена',
  created_by: 'Добавил',
  updated_at: 'Изменена',
  updated_by: 'Изменил',
}

export class Act {
  id = null

  car_id = null

  car_group_id = null

  driver_id = null

  plan_id = null

  mileage_start = null

  status = 'new'

  act_date = null

  act_time_start = null

  act_time_end = null

  constructor(obj = {}) {
    fillObject(this, obj)
  }
}
